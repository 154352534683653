
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}


@media (max-width: 1140px) {
	.header-bg-right{
		display: none;
	}
}
@media (max-width: 768px) {
	.header-bg-right{
		display: none;
	}
}
@media (max-width: 600px) {
	.header-bg-right{
		display: none;
	}
}

@media (max-width: 600px) {
	.header-bg-right1{
		display: none;
	}
}
@media (max-width: 600px) {
	.header-bg-right2{
		display: none;
	}
}


.header-bg-right {
    width: 600px;
    height: 600px;
    position: absolute;
    background-position: right center;
    right: 20px;
    bottom: 20px;
}
.header-bg-right1 {
    width: 500px;
    height: 450px;
    position: absolute;
    background-position: right center;
    right: 12px;
    bottom: 20px;
}
 
.header-bg-right2 {
    width: 550px;
    height: 450px;
    position: absolute;
    background-position: right center;
    right: 12px;
    bottom: 20px;
}
 
.bg-cartoon-1 {
    background-image: url("../img/vector/r01.png");  
    background-repeat: no-repeat;
    background-position: center;
    background-size: 300px;

}
.bg-cartoon-2 {
    background-image: url("../img/vector/a13.svg");  
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;

}
.bg-cartoon-3 {
    background-image: url("../img/vector/14.png");  
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;

}
.bg-cartoon-4 { 
    background-image: url("../img/vector/a1.gif");  
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;

}

.bg-cartoon-5 { 
    background-image: url("../img/vector/a16.png");      
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;

}
.bg-cartoon-6 { 
    background-image: url("../img/vector/a17.gif");      
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;

}
.bg-cartoon-7 {   
    background-image: url("../img/vector/a5.png");          
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;  
}
.bg-cartoon-8 {   
    background-image: url("../img/vector/s1.png");          
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;  
}
.bg-cartoon-9 {   
    background-image: url("../img/vector/a12.svg");          
    background-repeat: no-repeat;
    background-position: center;  
    background-size: 300px;  
}
.text-indigo {

	color: #3775d6 !important
}



.form-group .iti{
    width: 100% !important;
}

.form-group.has-error .form-control {
    border-color: #dd4b39;
    box-shadow: none;
}



//.btn-circle {
  //  font-size: 1.7rem;
//}


.btn-actions {  
    position: fixed;
    right: 36px;   
    width: 54px;
    z-index: 1000;
	flex-direction: column;
	bottom: 3rem;  
	display: flex ;
}
.social-bg-brand .social-instagram {
    background-color: #b92222;
}

.btn-actions .btn{
	opacity: 0.6 ;
}
.btn-actions .btn:hover{
	opacity: 1;
    transform: translateY(-2px);
    transition: .2s ease-out;
}

.btn-actions>.scroll-top { 
    position: initial;
    right: unset;   
    width: 54px;
    z-index: unset;
	bottom: unset;  
	display: flex ;
	opacity: 0.6 ;
}

.btn-actions .btn {
    margin-top: 1em;
}

.btn-actions .btn i.fa{
    font-size: 2em;
}





/*
	Responsive design for all devices
	https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
*/

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {

	.btn-actions{
		right: 15px !important;
		width: 25px;
		bottom: 3rem;  
		margin-right: 0; 
	}
	.btn-actions .btn{
		width: 24px;
		height: 24px;
		line-height: 24px;
	}
	.btn-actions .btn i.fa{
		font-size: 1em !important;
	}



}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {

	.btn-actions{
		right: 15px !important;
		width: 25px;
		bottom: 3rem;  
		margin-right: 0; 
	}
	.btn-actions .btn{
		width: 24px;
		height: 24px;
		line-height: 24px;
	}
	.btn-actions .btn i.fa{
		font-size: 1em !important;
	}



}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
	.btn-actions{
		right: 15px !important;
		width: 25px;
		bottom: 3rem;  
		margin-right: 0; 
	}
	.btn-actions .btn{
		width: 34px;
		height: 34px;
		line-height: 34px;
	}
	.btn-actions .btn i.fa{
		font-size: 1.5em !important;
	}

}

/* Large devices (laptops/desktops, 992px and up) */  
@media (min-width: 992px) {
	.btn-actions{
		right: 45px !important;
		width: 44px;
		bottom: 3rem;  
		margin-right: 0; 
	}
	.btn-actions .btn{
		width: 60px;
		height: 60px;
		line-height: 44px;
	}
	.btn-actions .btn i.fa{
		font-size: 1.8em !important;
	}

}

.header-bg-right.bg-cartoon-9.bg-size-2x {
    width: 500px;
    height: 500px;
    background-position: initial;
    background-size: 400px;
} 
  
.grecaptcha-badge{
	visibility:hidden;
}	  
