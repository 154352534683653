
.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {

  + .breadcrumb-item {
    padding-left: 0;

    &::before {
      padding: 0;
      margin: 0 0.75rem;
      width: 8px;
      height: 8px;
      border-right: 1px solid $breadcrumb-divider-color;
      border-top: 1px solid $breadcrumb-divider-color;
      vertical-align: middle;
      transform: rotate(45deg);
    }
  }

  a {
    color: $color-text-dark;

    &:hover {
      color: $color-primary;
    }
  }

}
